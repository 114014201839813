import React, { CSSProperties, PropsWithChildren, useEffect } from "react"
import { motion, useSpring } from "framer-motion"
import { down } from "styled-breakpoints"
import useBreakpoint from "../../../../hooks/useBreakpoint"
import ProgressIndicator from "../../../progress-indicator"
import * as Styled from "./styles"

type Props = {
  className: string
  aspectRatio?: CSSProperties["aspectRatio"]
  progress?: number
  onFullscreen?: () => void
  link: string
}

const ImageContainer: React.FC<PropsWithChildren<Props>> = ({
  className,
  children,
  aspectRatio,
  progress,
  onFullscreen,
  link,
}) => {
  const opacity = useSpring(0)
  const isTablet = useBreakpoint(down("lg"))

  useEffect(() => {
    if (isTablet) opacity.set(1)
    else opacity.set(0)
  }, [isTablet])

  return (
    <Styled.Container className={className} $klass={className} role="listitem">
      <Styled.Wrapper
        onMouseEnter={() => !isTablet && opacity.set(1)}
        onMouseLeave={() => !isTablet && opacity.set(0)}
      >
        <Styled.ImageWrapper style={{ aspectRatio, pointerEvents: "none" }}>
          {children}
        </Styled.ImageWrapper>
        <Styled.VerticalDivider direction="vertical" />
        <Styled.Toolbar role="region">
          <Styled.FullscreenButton
            title="Otwórz na pełnym ekranie"
            role="button"
            tabIndex={0}
            style={{ opacity }}
            onClick={onFullscreen}
            onKeyDown={e => {
              if (e.key === "Enter") onFullscreen?.()
            }}
          >
            <Styled.FullscreenSVG role="img" aria-label="Pełny ekran" />
          </Styled.FullscreenButton>
          {typeof progress !== "undefined" && (
            <ProgressIndicator progress={progress} />
          )}
          <Styled.ChapterLink to={link} title="Przejdź do rozdziału">
            <motion.span
              style={{ opacity, display: "flex", alignItems: "center" }}
            >
              <Styled.ArrowRightSVG role="img" aria-label="Strzałka w prawo" />
            </motion.span>
          </Styled.ChapterLink>
        </Styled.Toolbar>
      </Styled.Wrapper>
      <Styled.HorizontalDivider />
    </Styled.Container>
  )
}

export default ImageContainer
