import React from "react"
import { motion } from "framer-motion"
import styled from "styled-components"
import { Swiper } from "swiper/react"
import ArrowRightSVG from "../../images/arrow-right.inline.svg"
import ArrowHeadSVG from "../../images/arrow-head-down.inline.svg"
import CloseSVG from "../../images/x.inline.svg"
import { between, down, only, up } from "styled-breakpoints"

export const Position = styled(motion.section).attrs({
  initial: { opacity: 0, scale: 1.2 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 1.2 },
  transition: { duration: 0.2, ease: "easeOut" },
})`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.ivoryWhite};
  z-index: 12;
  display: flex;
  flex-direction: column;
`

export const Slider = styled(Swiper)`
  width: 100%;
  flex: 1 0 auto;
  display: flex;

  .swiper-wrapper {
    flex: 1 1 auto;
    height: initial;
  }
`

export const Slide = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${between("lg", "xl")} {
    padding: 0px 8.7rem;
  }

  ${only("xl")} {
    padding: 0px 16.4rem;
  }

  ${up("xxl")} {
    padding: 0px 14rem;
  }
`

export const NavigationButton = styled.button`
  cursor: pointer;

  &:first-of-type {
    margin-right: 10rem;

    ${down("lg")} {
      margin: 0 1rem;
    }
  }

  &:last-of-type {
    margin-left: 10rem;

    ${down("lg")} {
      margin: 0 1rem;
    }
  }
`

export const ArrowRightIcon = styled(ArrowRightSVG)`
  margin-left: 1.6rem;
  height: 0.9em;
  width: auto;
`

export const ArrowHeadRightIcon = styled(ArrowHeadSVG)`
  transform: rotate(-90deg);
  height: 2.7rem;
  width: auto;

  & path {
    stroke: ${({ theme }) => theme.colors.blue};
    stroke-width: 0.12rem;
  }
`

export const ArrowHeadLeftIcon = styled(ArrowHeadSVG)`
  transform: rotate(90deg);
  height: 2.7rem;
  width: auto;

  & path {
    stroke: ${({ theme }) => theme.colors.blue};
    stroke-width: 0.12rem;
  }
`

export const CarouselHeader = styled(motion.header).attrs({
  initial: { translateY: -150 },
  animate: { translateY: 0 },
  exit: { translateY: -150 },
  transition: { delay: 0.3 },
})`
  margin: 0px 3.2rem;

  ${between("md", "xl")} {
    margin: 0px 8.7rem;
  }

  ${only("xl")} {
    margin: 0px 16.4rem;
  }

  ${up("xxl")} {
    margin: 0px 14rem;
  }
`

export const CarouselFooter = styled(motion.footer).attrs({
  initial: { translateY: 150 },
  animate: { translateY: 0 },
  exit: { translateY: 150 },
  transition: { delay: 0.3 },
})`
  margin: 0px 3.2rem;

  ${between("md", "xl")} {
    margin: 0px 8.7rem;
  }

  ${only("xl")} {
    margin: 0px 16.4rem;
  }

  ${up("xxl")} {
    margin: 0px 14rem;
  }
`

export const CloseButton = styled.button`
  cursor: pointer;
  margin-left: auto;
`

export const CloseIcon = styled(CloseSVG)`
  & path {
    fill: ${({ theme }) => theme.colors.blue};
  }
`

export const Spacer = styled(CloseButton).attrs({
  children: <CloseIcon />,
})`
  opacity: 0;
  visibility: 0;
  pointer-events: none;
`
