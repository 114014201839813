import { between, down, up } from "styled-breakpoints"
import styled from "styled-components"
import ListElement from "../../components/list-element"
import mdx from "../../components/mdx"
import { FlexColumn } from "../../components/system/flex-column"
import { FlexRow } from "../../components/system/flex-row"

export const Layout = styled(FlexRow)`
  flex-wrap: wrap;
  background: ${({ theme }) => theme.colors.ivoryWhite};
  padding: 0 1.8rem;

  ${between("md", "xl")} {
    padding: 0 3.5rem;
  }

  ${between("xl", "xxl")} {
    padding: 0 6.5rem;
  }

  ${up("xxl")} {
    padding: 0 14rem;
  }
`

export const Spacer = styled(FlexColumn)`
  display: none;
  flex: 1;

  ${up("lg")} {
    display: initial;
    flex: 3;
  }

  ${up("xl")} {
    flex: 5;
  }
`

export const Content = styled(FlexColumn)`
  flex: 1;
  padding-right: 5vw;
  padding-left: 5vw;
`

export const ChapterListItem = styled(ListElement)`
  width: 100%;

  ${down("lg")} {
    flex-basis: auto !important;
  }

  ${up("lg")} {
    flex: 6;
  }

  ${up("xl")} {
    flex: 7;
  }
`

export const TableOfContents = styled.ol`
  margin-top: 4rem;
  margin-bottom: 4rem;

  ${up("lg")} {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
`
export const ChapterLink = styled(mdx.Link)`
  font: inherit;
  margin: 0;
`

export const ImagesGridWrapped = styled.div``
