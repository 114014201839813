import React, { PropsWithChildren, useEffect, useState } from "react"
import { useSwiper, SwiperSlide } from "swiper/react"
import { Navigation, A11y, Keyboard, Swiper } from "swiper"
import * as Styled from "./styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Divider from "../divider"
import { Typography } from "../system/typography"
import { FlexRow } from "../system/flex-row"
import { Image, Video as VideoType } from "../../types/config"
import { isImage } from "../../types/guards"
import Video from "../mdx/video"
import mdx from "../mdx"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

type Props = {
  onExit: () => void
  startingSlide: number
  title: string
  slug: string
  images: (Image | VideoType)[]
}

const Carousel: React.FC<Props> = ({
  onExit,
  startingSlide,
  title,
  slug,
  images,
}) => {
  const [swiper, setSwiper] = useState<Swiper | null>(null)
  const [link, setLink] = useState("")

  useEffect(() => {
    if (!swiper) return

    swiper.slideTo(startingSlide, 0)
    const id = images[startingSlide].id
    setLink(`/${slug}#${id}`)

    const listener = (e: KeyboardEvent) => {
      switch (e.key) {
        case "Escape":
          onExit()
          break
        case "ArrowRight":
          swiper.slideNext()
          break
        case "ArrowLeft":
          swiper.slidePrev()
          break
      }
    }

    const handleSlidechange = (e: Swiper) => {
      const id = images[e.activeIndex].id
      setLink(`/${slug}#${id}`)
    }

    swiper.on("slideChange", handleSlidechange)
    window.addEventListener("keydown", listener)
    return () => {
      swiper.off("slideChange", handleSlidechange)
      window.removeEventListener("keydown", listener)
    }
  }, [swiper, startingSlide, slug, images])

  return (
    <Styled.Position>
      <Styled.CarouselHeader>
        <FlexRow display={{ xs: "flex", sm: "flex" }}>
          <Styled.Spacer />
          <Typography
            variant="caption"
            textAlign="center"
            my="4rem !important"
            mx="2rem"
          >
            {title}
          </Typography>
          <Styled.CloseButton onClick={onExit}>
            <Styled.CloseIcon role="img" aria-label="Zamknij karuzelę" />
          </Styled.CloseButton>
        </FlexRow>
        <Divider />
      </Styled.CarouselHeader>
      <Styled.Slider
        modules={[Navigation, Keyboard, A11y]}
        slidesPerView={1}
        onSwiper={setSwiper}
      >
        {images.map(image => {
          let renderedFile

          if (isImage(image)) {
            const imageData = getImage(image.path.childImageSharp)
            if (!imageData) return undefined

            renderedFile = (
              <GatsbyImage
                alt="dna"
                objectFit="contain"
                image={imageData}
                imgStyle={{
                  maxWidth: "60vw",
                  maxHeight: "60vh",
                  aspectRatio: `${imageData.width} / ${imageData.height}`,
                }}
                style={{
                  maxWidth: "60vw",
                  maxHeight: "60vh",
                  aspectRatio: `${imageData.width} / ${imageData.height}`,
                }}
              />
            )
          } else {
            renderedFile = (
              <Video
                video={image}
                videoStyle={{ maxWidth: "60vw", maxHeight: "60vh" }}
              />
            )
          }

          return (
            <SwiperSlide key={`fullscreen__${image.id}`}>
              <Slide>{renderedFile}</Slide>
            </SwiperSlide>
          )
        })}
      </Styled.Slider>
      <Styled.CarouselFooter>
        <Divider />
        <Typography variant="caption" textAlign="center" my="4rem !important">
          <mdx.Link to={link} style={{ font: "inherit" }}>
            Znajdź w książce <Styled.ArrowRightIcon />
          </mdx.Link>
        </Typography>
      </Styled.CarouselFooter>
    </Styled.Position>
  )
}

export default Carousel

const Slide: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const swiper = useSwiper()

  return (
    <Styled.Slide>
      <Styled.NavigationButton onClick={() => swiper.slidePrev()}>
        <Styled.ArrowHeadLeftIcon />
      </Styled.NavigationButton>
      {children}
      <Styled.NavigationButton onClick={() => swiper.slideNext()}>
        <Styled.ArrowHeadRightIcon />
      </Styled.NavigationButton>
    </Styled.Slide>
  )
}
