import { AnimatePresence } from "framer-motion"
import { graphql, PageProps } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { Reducer, useCallback, useMemo, useReducer } from "react"
import Carousel from "../components/carousel"
import ContinueLink from "../components/continue-link"
import Divider from "../components/divider"
import AdvancedHeader from "../components/header/advanced"
import ImageChapterItem from "../components/image-item"
import ImageContainer from "../components/image-item/components/image-container"
import Video from "../components/mdx/video"
import PageHeading from "../components/page-heading"
import ScrollUpCTA from "../components/ScrollUpCTA"
import Seo from "../components/seo"
import { useProgress } from "../hooks/useProgress"
import * as Styled from "../styles/pages/images"
import { Image, Video as VideoType } from "../types/config"
import { isImage } from "../types/guards"
import { getChapterNodeTitle } from "../util/getChapterNodeTitle"
import { sortChapters } from "../util/sortChapters"

interface Data {
  allMdx: {
    nodes: Array<{
      id: string
      slug: string
      body: string & React.ReactNode
      frontmatter: {
        index: number
        title: string
        images: Image[]
        videos: VideoType[]
      }
    }>
  }
}

const ImagesPage: React.FC<PageProps<Data>> = ({ data }) => {
  const carousel = useCarousel()
  const [progress] = useProgress()
  console.log("🚀 ~ file: images.tsx:41 ~ progress:", progress)

  const contents = useMemo(() => {
    const mdx = data.allMdx.nodes.filter(filterImageChapters).sort(sortChapters)

    return mdx.map(node => {
      const title = getChapterNodeTitle(node)
      const images = node.frontmatter.images ?? []
      const videos = node.frontmatter.videos ?? []
      const files = [...images, ...videos].sort(sortFiles)

      return (
        <ImageChapterItem
          key={node.id}
          lookupClass="COL"
          title={title}
          subtitle={node.frontmatter.title}
          keyPrefix={node.id}
          link={`/${node.slug}`}
          progress={
            progress.link && `/${node.slug}/`.includes(progress.link)
              ? progress.completion
              : undefined
          }
        >
          {files.map((file, i) => {
            const onFullscreen = () => {
              carousel.setStartingIndex(i)
              carousel.setMdxId(node.id)
              carousel.setTitle(
                [title, node.frontmatter.title].filter(Boolean).join(" / ")
              )
              carousel.setSlug(node.slug)
              carousel.toggleFullscreen()
            }

            let renderedFile

            if (isImage(file)) {
              const imageData = getImage(file.path.childImageSharp)
              if (!imageData) return undefined

              renderedFile = (
                <GatsbyImage
                  alt={file.alt}
                  objectFit="contain"
                  image={imageData}
                  imgStyle={{
                    maxWidth: 500,
                    maxHeight: 300,
                    aspectRatio: `${imageData.width} / ${imageData.height}`,
                  }}
                  style={{
                    maxWidth: 500,
                    maxHeight: 300,
                    aspectRatio: `${imageData.width} / ${imageData.height}`,
                  }}
                />
              )
            } else {
              renderedFile = (
                <Video
                  video={file}
                  videoStyle={{ maxWidth: 500, maxHeight: 240 }}
                />
              )
            }

            return (
              <ImageContainer
                key={`${node.id}__${file.id}`}
                className="COL"
                onFullscreen={onFullscreen}
                link={`/${node.slug}#${file.id}`}
                progress={
                  file.id === progress.image ? progress.completion : undefined
                }
              >
                {renderedFile}
              </ImageContainer>
            )
          })}
        </ImageChapterItem>
      )
    })
  }, [data.allMdx.nodes, progress])

  const carouselFiles = useMemo(() => {
    const chapter = data.allMdx.nodes.find(c => c.id === carousel.state.mdxId)
    if (!chapter || !chapter.frontmatter) return []
    const images = chapter.frontmatter.images ?? []
    const videos = chapter.frontmatter.videos ?? []
    return [...images, ...videos].sort(sortFiles)
  }, [data.allMdx.nodes, carousel.state.mdxId])

  return (
    <>
      <Seo title="Przegląd grafik" />
      <AdvancedHeader progress={<ContinueLink />} />
      <Styled.Layout>
        <PageHeading>Przegląd grafik</PageHeading>
        <Styled.Content>
          <Styled.TableOfContents aria-label="Przegląd grafik">
            {contents}
          </Styled.TableOfContents>
          <ScrollUpCTA />
        </Styled.Content>
        <Divider
          mt={{ xs: "4rem", md: "7rem", lg: "9.5rem" }}
          mb={{ xs: "5rem", md: "7rem" }}
        />
      </Styled.Layout>
      <AnimatePresence exitBeforeEnter>
        {carousel.state.isOpen && (
          <Carousel
            title={carousel.state.title}
            startingSlide={carousel.state.startingSlide}
            onExit={carousel.toggleFullscreen}
            slug={carousel.state.slug}
            images={carouselFiles}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default ImagesPage

export const query = graphql`
  query {
    allMdx {
      nodes {
        id
        slug
        frontmatter {
          index
          title
          images {
            id
            alt
            path {
              name
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  # height: 300
                )
              }
            }
          }
          videos {
            id
            alt
            mp4 {
              publicURL
              name
            }
            gif {
              publicURL
              name
            }
          }
        }
      }
    }
  }
`

const collator = new Intl.Collator([], { numeric: true })

const sortFiles = (a: Image | VideoType, b: Image | VideoType) => {
  const _a = isImage(a) ? a.path.name : a.mp4?.name ?? a.gif?.name
  const _b = isImage(b) ? b.path.name : b.mp4?.name ?? b.gif?.name
  return collator.compare(_a, _b)
}

const filterImageChapters = (n: Data["allMdx"]["nodes"][0]) => {
  return (
    n.frontmatter &&
    (n.frontmatter.images?.length > 0 || n.frontmatter?.videos?.length > 0) &&
    n.frontmatter.index !== null
  )
}

interface CarouselState {
  title: string
  mdxId: string
  startingSlide: number
  isOpen: boolean
  slug: string
}

type CarouselOpen = { type: "carousel__open" }
type CarouselSetStartingSlide = {
  type: "carousel__set-starting-slide"
  payload: number
}
type CarouselSetTitle = {
  type: "carousel__set-title"
  payload: string
}
type CarouselSetSlug = {
  type: "carousel__set-slug"
  payload: string
}
type CarouselSetMdxId = {
  type: "carousel__set-mdx-id"
  payload: string
}
type CarouselActions =
  | CarouselOpen
  | CarouselSetStartingSlide
  | CarouselSetTitle
  | CarouselSetMdxId
  | CarouselSetSlug

const carouselReducer: Reducer<CarouselState, CarouselActions> = (
  state,
  action
) => {
  switch (action.type) {
    case "carousel__open":
      return { ...state, isOpen: !state.isOpen }
    case "carousel__set-starting-slide":
      return { ...state, startingSlide: action.payload }
    case "carousel__set-title":
      return { ...state, title: action.payload }
    case "carousel__set-mdx-id":
      return { ...state, mdxId: action.payload }
    case "carousel__set-slug":
      return { ...state, slug: action.payload }
    default:
      return state
  }
}

const initialState: CarouselState = {
  isOpen: false,
  startingSlide: 0,
  title: "",
  mdxId: "",
  slug: "",
}

const useCarousel = () => {
  const [state, dispatch] = useReducer(carouselReducer, initialState)

  const toggleFullscreen = useCallback(
    () => dispatch({ type: "carousel__open" }),
    []
  )

  const setStartingIndex = useCallback(
    (index: number) =>
      dispatch({ type: "carousel__set-starting-slide", payload: index }),
    []
  )

  const setTitle = useCallback(
    (title: string) =>
      dispatch({ type: "carousel__set-title", payload: title }),
    []
  )

  const setMdxId = useCallback(
    (id: string) => dispatch({ type: "carousel__set-mdx-id", payload: id }),
    []
  )

  const setSlug = useCallback(
    (id: string) => dispatch({ type: "carousel__set-slug", payload: id }),
    []
  )

  return {
    state,
    toggleFullscreen,
    setStartingIndex,
    setTitle,
    setMdxId,
    setSlug,
  }
}
