import React, { PropsWithChildren } from "react"
import { useRefEffect } from "../../../../hooks/useRefEffect"
import * as Styled from "./styles"

type Props = PropsWithChildren<{ dependencies?: any[]; lookupClass: string }>

const LayoutObserver: React.FC<Props> = ({
  children,
  dependencies,
  lookupClass,
}) => {
  const [_, setRef] = useRefEffect<HTMLDivElement>(node => {
    const styleElements = () => {
      const nodes = node.parentElement?.querySelectorAll(`.${lookupClass}`)
      if (!nodes) return

      let leftEl: Element[] = []
      let rightEl: Element[] = []

      // * Find left and right elements on the edges of the container
      nodes.forEach(n => {
        n.classList.remove(`${lookupClass}--left`, `${lookupClass}--right`)
        n.removeAttribute("id")
        const { left, right } = n.getBoundingClientRect()

        if (leftEl.length > 0) {
          const rectLeft = leftEl[0].getBoundingClientRect().left

          // * Check for elements in same column with small range
          if (rectLeft > left - 10 && rectLeft < left + 10) leftEl.push(n)
          else if (rectLeft > left) leftEl = [n]
        } else {
          leftEl.push(n)
        }

        if (rightEl.length > 0) {
          const rectRight = rightEl[0].getBoundingClientRect().right

          // * Check for elements in same column with small range
          if (rectRight > right - 10 && rectRight < right + 10) rightEl.push(n)
          else if (rectRight < right) rightEl = [n]
        } else {
          rightEl.push(n)
        }
      })

      leftEl.forEach((el, i, { length }) => {
        el.classList.add("COL--left")
        if (i === length - 1) el.setAttribute("id", "last--left")
      })
      rightEl.forEach((el, i, { length }) => {
        el.classList.add("COL--right")
        if (i === length - 1) el.setAttribute("id", "last--right")
      })
    }

    styleElements()
    window.addEventListener("resize", styleElements)
    return () => {
      window.removeEventListener("resize", styleElements)
    }
  }, dependencies!)

  return (
    <Styled.ObservingContainer ref={setRef} role="list">
      {children}
    </Styled.ObservingContainer>
  )
}

LayoutObserver.defaultProps = { dependencies: [] }

export default LayoutObserver
