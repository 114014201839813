import { down, up } from "styled-breakpoints"
import styled, { css } from "styled-components"
import Divider from "../../../divider"
import Fullscreen from "../../../../images/fullscreen.inline.svg"
import ArrowRight from "../../../../images/arrow-right.inline.svg"
import { Typography } from "../../../system/typography"
import { PropsFrom } from "../../../../types/utils"
import { motion } from "framer-motion"
import { IndicatorContainer } from "../../../progress-indicator/styles"
import { HistoryLink } from "../../../../context/history"

export const FullscreenSVG = styled(Fullscreen)`
  height: 1em;
  cursor: pointer;
`

export const ArrowRightSVG = styled(ArrowRight)`
  height: 1em;
  cursor: pointer;
`

export const HorizontalDivider = styled(Divider)`
  position: absolute;
  left: -100vw;
  right: -100vw;
  bottom: 0;
  width: calc(100% + 200vw);
`
HorizontalDivider.defaultProps = {
  variant: "dashed",
  role: "none",
}

export const VerticalDivider = styled(Divider)`
  position: absolute;
  right: 0;
  bottom: 3rem;
  top: 3rem;
  height: calc(100% - 3rem - 3rem);

  ${up("md")} {
    top: 3rem;
    bottom: calc(3rem - 0.5em);
    height: calc(100% - 3rem - 3rem + 0.5em);
  }

  ${up("xl")} {
    top: 4rem;
    bottom: calc(4rem - 0.5em);
    height: calc(100% - 4rem - 4rem + 0.5em);
  }

  ${up("xxl")} {
    top: 5rem;
    bottom: calc(5rem - 0.5em);
    height: calc(100% - 5rem - 5rem + 0.5em);
  }
`
VerticalDivider.defaultProps = {
  variant: "dashed",
  direction: "vertical",
  role: "none",
}

export const Wrapper = styled(motion.article)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 4rem;
  padding-bottom: 3rem;
  height: 100%;

  ${down("md")} {
    width: 100%;
  }

  ${up("md")} {
    padding-top: 6rem;
    padding-bottom: calc(3rem - 0.75em);
    padding-left: 6rem;
    padding-right: 6rem;
  }

  ${up("xl")} {
    padding-top: 8rem;
    padding-bottom: calc(4rem - 0.75em);
    padding-left: 8rem;
    padding-right: 8rem;
  }

  ${up("xxl")} {
    padding-top: 10rem;
    padding-bottom: calc(5rem - 0.75em);
    padding-left: 10rem;
    padding-right: 10rem;
  }
`

Wrapper.defaultProps = {
  variants: { hidden: { opacity: 0, y: -20 }, visible: { opacity: 1, y: 0 } },
  transition: { duration: 0.4, ease: "easeInOut" },
}

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  max-height: 240px;
  margin: auto;

  ${up("xl")} {
    max-height: 310px;
  }

  ${up("xl")} {
    max-height: 430px;
  }
`

export const FullscreenButton = styled(motion.div)`
  order: 1;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${up("md")} {
    order: 2;
  }
`

export const ChapterLink = styled(HistoryLink)`
  order: 3;
  width: 2rem;
  display: flex;
  justify-content: center;
`

export const Toolbar = styled(Typography)`
  width: 100%;
  height: 1.5em;
  display: flex;
  margin-top: 4rem;
  justify-content: space-between;
  gap: 1rem;

  ${up("md")} {
    justify-content: flex-end;
    margin-top: calc(3rem - 0.75em);
  }

  ${up("xl")} {
    margin-top: calc(4rem - 0.75em);
  }

  ${up("xxl")} {
    margin-top: calc(5rem - 0.75em);
  }

  & ${IndicatorContainer} {
    ${up("md")} {
      padding-left: 6rem;
    }
  }
`

Toolbar.defaultProps = {
  as: "div",
  variant: "caption",
} as PropsFrom<typeof Toolbar>

export const Container = styled(motion.div)<{ $klass: string }>`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* max-height: 300; */

  ${down("md")} {
    width: 100%;
  }

  ${({ $klass: klass }) => css`
    & ${HorizontalDivider} {
      display: none;
    }

    &.${klass}--left {
      flex: 100 0 auto;
      align-items: flex-end;

      & ${HorizontalDivider} {
        display: block;
      }
    }

    &.${klass}--right {
      flex: 100 0 auto;
      align-items: flex-start;

      & ${HorizontalDivider} {
        display: block;
      }

      & ${VerticalDivider} {
        display: none;
      }
    }

    &.${klass}--left.${klass}--right {
      align-items: center;

      & ${HorizontalDivider} {
        display: block;
      }

      & ${VerticalDivider} {
        display: none;
      }
    }

    &.${klass}--left#last--left, &.${klass}--right#last--right {
      & ${HorizontalDivider} {
        display: none;
      }
    }
  `};
`

Container.defaultProps = {
  variants: { hidden: { opacity: 0 }, visible: { opacity: 1 } },
  transition: { duration: 0.4, ease: "easeInOut" },
}
