import { motion } from "framer-motion"
import React, { PropsWithChildren } from "react"
import Divider from "../divider"
import { FlexRow } from "../system/flex-row"
import LayoutObserver from "./components/layout-observer"
import * as Styled from "./styles"

type Props = PropsWithChildren<{
  title: string
  subtitle: string
  keyPrefix: string
  link: string
  lookupClass: string
  progress?: number
}>

const ImageChapterItem: React.FC<Props> = ({
  title,
  subtitle,
  link,
  keyPrefix,
  children,
  lookupClass,
  progress,
}) => {
  return (
    <FlexRow flexWrap="wrap" as="li">
      <Styled.Spacer />
      <Styled.ChapterListItem
        component={motion.div}
        variableFlexBasis
        title={title}
        keyPrefix={keyPrefix}
        progress={progress}
        subtitle={<Styled.ChapterLink to={link}>{subtitle}</Styled.ChapterLink>}
      >
        {({ layouted }) => (
          <>
            <Divider mt={{ XS: "4rem", lg: "6rem" }} role="none" />
            <LayoutObserver dependencies={[layouted]} lookupClass={lookupClass}>
              {children}
            </LayoutObserver>
          </>
        )}
      </Styled.ChapterListItem>
    </FlexRow>
  )
}

export default ImageChapterItem
