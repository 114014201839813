import { down, up } from "styled-breakpoints"
import styled from "styled-components"
import ListElement from "../list-element"
import { Content } from "../list-element/styles"
import mdx from "../mdx"
import { FlexColumn } from "../system/flex-column"

export const ChapterListItem = styled(ListElement)`
  width: 100%;

  ${down("lg")} {
    flex-basis: auto !important;
  }

  ${up("lg")} {
    flex: 6;
  }

  ${up("xl")} {
    flex: 7;
  }

  & ${Content} {
    padding-top: 0;
  }
`

export const Spacer = styled(FlexColumn)`
  display: none;
  flex: 1;

  ${up("lg")} {
    display: initial;
    flex: 3;
  }

  ${up("xl")} {
    flex: 5;
  }
`

export const ChapterLink = styled(mdx.Link)`
  font: inherit;
  margin: 0;
`
