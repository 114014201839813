import styled from "styled-components"
import { FlexRow } from "../../../system/flex-row"

export const ObservingContainer = styled(FlexRow)`
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  position: relative;
  gap: 0 !important;
`
